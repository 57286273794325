import { Box, Typography } from "@mui/material";
import CustomButton from "app/components/Button";
import Modal from "app/components/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

interface RegisterUserSuccessProps {
  open: boolean;
  handleClose: () => void;
}

export default function RegisterUserSuccess({
  open,
  handleClose,
}: RegisterUserSuccessProps) {
  const { t } = useTranslation();
  return (
    <Modal size="sm" open={open} handleClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "32px 24px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "26.4px",
          }}
        >
          {t("USERS.LINK_SUCCESSFULLY_SENT")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "62px",
            width: "100%",
            gap: "24px",
          }}
        >
          <CustomButton
            fullWidth={false}
            btnStyle={{
              borderRadius: "20px",
              minWidth: "160px",
              height: "50px",
              color: "#ffffff",
            }}
            onClick={handleClose}
          >
            <Typography>{t("BUTTONS.OK")}</Typography>
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
}
