import { Box, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Key from "app/containers/Dashboard/components/Key";
import { BarHistory, KeyTypes } from "app/containers/Dashboard/types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
  VictoryZoomContainer,
} from "victory";

const Keys: KeyTypes[] = [
  {
    color: "yellow",
    label: "REGISTERED_USERS",
  },
  {
    color: "blue",
    label: "LOGGED_IN_USERS",
  },
  {
    color: "grey",
    label: "DELAYED_USERS",
  },
  {
    color: "pink",
    label: "ALL",
  },
];
type Props = {
  history: { data: BarHistory; tickValues: number[]; allowZoom: boolean };
};
export default function Barchart({ history }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const allowZoom = history.allowZoom;
  return (
    <Grid2 spacing={3} sx={{ marginBottom: "40px" }} container>
      <Grid2 sm={12}>
        <Box
          sx={{
            borderRadius: "30px",
            border: "2.973px solid #EFF0F6",
            background: "#FFF",
            padding: "31px 34px",
            direction: theme.direction,
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: " 700",
              marginBottom: "39px",
            }}
          >
            {t(`DASHBOARD.GRAPH_TITLE`)}
          </Typography>

          <VictoryChart
            theme={VictoryTheme.material}
            width={600}
            height={300}
            domainPadding={{ x: [50, 50], y: [0, 0] }}
            containerComponent={
              <VictoryZoomContainer
                allowPan={allowZoom}
                allowZoom={false}
                zoomDomain={allowZoom ? { x: [2, 10] } : undefined}
                style={allowZoom ? { cursor: "ew-resize" } : {}}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              tickLabelComponent={
                <VictoryLabel
                  verticalAnchor="middle"
                  textAnchor="middle"
                  x={20}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                  }}
                />
              }
              tickCount={8}
              tickValues={history.tickValues}
            />
            <VictoryAxis
              tickCount={8}
              tickFormat={(x) => x}
              tickLabelComponent={
                <VictoryLabel
                  verticalAnchor="middle"
                  textAnchor="middle"
                  angle={-45}
                  dy={15}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                  }}
                />
              }
            />

            <VictoryGroup
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              offset={10}
            >
              {history.data.map((h) => {
                return (
                  <VictoryBar
                    data={h.bars}
                    alignment="middle"
                    //key={h.type}
                    barWidth={8}
                    style={{ data: { fill: h.color } }}
                  />
                );
              })}
            </VictoryGroup>
          </VictoryChart>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "32px",
            }}
          >
            {Keys.map((key, index) => (
              <Key
                key={index}
                color={key.color}
                label={t(`DASHBOARD.${key.label}`)}
              />
            ))}
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
}
