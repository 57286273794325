import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingWaterCompanies,
  selectWaterCompanies,
  selectWaterCompany,
} from "store/fields/global/selectors";
import { actions, actions as globalActions } from "store/fields/global/slice";
import { selectUser } from "store/fields/permissions/selectors";
import { Roles } from "utils/enum";
import AsyncSelect from "../AsyncSelect";

export default function WaterCompaniesSelect({
  onChange,
  value,
  error,
  disabled,
  required = false,
  showAll = true,
}: {
  onChange?: (val: { label: string; value: string }) => void;
  value?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  showAll?: boolean;
}) {
  const waterCompany = useSelector(selectWaterCompany);
  const isLoading = useSelector(selectIsLoadingWaterCompanies);
  const WaterCompanies = useSelector(selectWaterCompanies);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.role === Roles.BACK_OFFICE) {
      if (user?.userMeta?.waterCompany?.id) {
        dispatch(
          globalActions.setWaterCompany({
            label: user?.userMeta?.waterCompany?.name,
            value: user?.userMeta?.waterCompany?.id,
          })
        );
      }
    }
  }, [
    user?.role,
    user?.userMeta?.waterCompany?.id,
    user?.userMeta?.waterCompany?.name,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(
      actions.getWaterCompanies({
        limit: 200,
      })
    );
    return () => {
      if (showAll) {
        dispatch(
          actions.setWaterCompany({
            label: "All",
            value: "all",
          })
        );
      }
    };
  }, [dispatch, showAll]);

  return (
    <AsyncSelect
      required={required}
      labelStyle={{
        marginLeft: 0,
      }}
      label={t("COMMON.WATER_COMPANY")}
      error={error}
      disabled={disabled || user?.role === Roles.BACK_OFFICE}
      value={
        typeof value === "string"
          ? {
              label:
                WaterCompanies?.find((item) => item.id?.toString() === value)
                  ?.name ?? t("USERS.SELECT_WATER_COMPANY"),
              value: value ?? "",
            }
          : waterCompany
      }
      options={
        showAll
          ? [
              {
                label: "All",
                value: "all",
              },
              ...WaterCompanies?.map((item) => ({
                label: item.name,
                value: item.id?.toString(),
              })),
            ]
          : WaterCompanies?.map((item) => ({
              label: item.name,
              value: item.id?.toString(),
            }))
      }
      defaultValue={
        showAll
          ? {
              label: "All",
              value: "all",
            }
          : undefined
      }
      onBlur={(e) => {
        if (!e.target.value) {
          dispatch(
            actions.setWaterCompany({
              label: showAll ? "All" : "",
              value: showAll ? "all" : "",
            })
          );
        }
      }}
      fullWidth={true}
      loading={isLoading}
      loadingText={t("COMMON.LOADING")}
      noOptionsText={t("COMMON.NO_OPTIONS")}
      onChange={(e, val) => {
        if (onChange) {
          onChange(
            val ?? {
              label: "",
              value: "",
            }
          );
          return;
        }
        dispatch(
          actions.setWaterCompany(
            val ?? {
              label: "",
              value: "",
            }
          )
        );
      }}
    />
  );
}
