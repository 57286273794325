import { Box, Typography } from "@mui/material";
import React from "react";
import { Colors } from "utils/constants";

interface KeySummaryProps {
  color: "green" | "yellow" | "blue" | "grey" | "pink";
  label?: string;
  caption?: string;
}

export default function KeySummary({ color, label, caption }: KeySummaryProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "14.76px",
        padding: 0,
      }}
    >
      <Box
        sx={{
          width: "35.24px",
          height: "23.655px",
          borderRadius: "8.918px",
          background: Colors[color],
        }}
      />
      <Typography
        sx={{
          color: "rgba(0, 0, 0, 0.70)",
          fontFamily: "Roboto",
          fontSize: "18px",
          fontWeight: 700,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label}
        <span
          style={{
            paddingLeft: "5px",
            color: "rgba(0, 0, 0, 0.50)",
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {caption}
        </span>
      </Typography>
    </Box>
  );
}
