import { Box, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { useTranslation } from "react-i18next";
import { VictoryPie } from "victory";
import { KeyTypes, StatType } from "../../types";
import KeySummary from "../KeySummary";

interface DailyStats {
  title: string;
  data?: StatType;
}

export default function RingChart({
  title,
  data = {
    registered: 0,
    login: 0,
    delayed: 0,
    total: 0,
  },
}: DailyStats) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { registered, login, delayed, total } = data;

  const keys: KeyTypes[] = [
    {
      color: "yellow",
      label:
        total > 0
          ? `${registered} (${Number((registered / total) * 100)?.toFixed()}%)`
          : `${registered}`,
      caption: "REGISTERED",
    },
    {
      color: "blue",
      label:
        total > 0
          ? `${login} (${Number((login / total) * 100)?.toFixed()}%)`
          : `${login}`,
      caption: "LOGIN",
    },
    {
      color: "grey",
      label:
        total > 0
          ? `${delayed} (${Number((delayed / total) * 100)?.toFixed()}%)`
          : `${delayed}`,
      caption: "DELAYED",
    },
  ];

  const chartData = [
    { x: 2, y: registered, fill: "#DCD526" },
    { x: 3, y: login, fill: "#4596FF" },
    { x: 4, y: delayed, fill: "#9FA2A5" },
  ];
  return (
    <Grid2 md={12} lg={6}>
      <Box
        sx={{
          borderRadius: "30px",
          border: "3px solid #EFF0F6",
          background: "#FFFFFF",
          padding: "31px 17px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          direction: theme.direction,
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: "#464646",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "26.4px",
              marginBottom: "15.86px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "normal",
              fontFamily: "Roboto",
              marginBottom: "24px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {total} {t("DASHBOARD.USERS")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "12px",
              flexDirection: "column",
            }}
          >
            {keys.map((key, index) => (
              <KeySummary
                key={index}
                color={key.color}
                label={key.label}
                caption={t(`DASHBOARD.${key.caption}`)}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: "50%",
          }}
        >
          <svg viewBox="0 0 100 100">
            <VictoryPie
              standalone={false}
              width={100}
              height={100}
              data={chartData}
              innerRadius={30}
              padding={10}
              labels={({ datum }) => null}
              colorScale={chartData.map((m) => m.fill)}
            />
          </svg>
        </Box>
      </Box>
    </Grid2>
  );
}
